body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsl(0, 0%, 95%);
  text-align: center;
  min-height: 100vh;
  width: 100%;
}

main {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.section {
  padding: 1rem 1.5rem;
}

.box {
  max-width: 600px;
  margin: 15px auto;
}

.form-error {
  border: 1px solid red;
}

.navbar {
  justify-content: center;
}

.navbar-brand {
  align-items: center;
}

.btn {
  font-family: "Catamaran", sans-serif;
  font-weight: 900;
}

.ut-btn {
  margin-top: 4px;
  height: 3.5rem;
  width: 10rem;
  font-size: 16px;
}

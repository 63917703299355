#empty-card {
  margin-top: 60px;
  margin-bottom: 60px;
  max-width: 500px;
  height: 200px;
}

.empty-header {
  justify-content: center;
}

.project-col {
  display: flex;
  justify-content: left;
}

.btn-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  margin-top: 15px;
  width: 40em;
  height: 25rem;
  overflow-y: scroll;
}

.card::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

.btn-row::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

@media (max-width: 1504px) {
  .project-col {
    justify-content: center;
  }
  .card::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  .card::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
      inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
}

@media (max-width: 375px) {
  .btn {
    margin-top: 15px;
  }

  .navbar {
    display: flex;
    justify-content: center;
  }

  .card {
    font-size: 1rem !important;
    min-width: 18rem;
  }

  .card-header {
    justify-content: center;
  }
}
